import React from 'react'

import LoadingAnimation from '../components/loading'

const Loading = () => (
  <div className='loading-page-container'>
    <LoadingAnimation />
    <h4>Pumas Cleaning</h4>
  </div>
)

export default Loading
